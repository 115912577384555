<template>
   <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="d-flex justify-space-between blue-grey lighten-5 pr-1 py-1">
        <div class="d-flex aling-center">
          <v-icon left color="primary" size="22">mdi-shape-polygon-plus</v-icon>
          <span class="display-1 primary--text font-weight-medium">Área Seleccionada</span>
        </div>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="blue-grey lighten-5 pb-1">
        <span class="primary--text text--lighten-1 ml-7 pt-2">Clientes:
          <strong>{{clients.length}} seleccionados</strong>
        </span>
      </v-card-subtitle>
      <v-card-text class="log-details content-scroll px-0 pb-1">
        <v-data-iterator
          :items="clients"
          hide-default-footer
          disable-pagination
          class="mb-0 pb-0"
        >
          <template v-slot:default="props">
            <v-list
              subheader
              two-line
              flat
              class="pt-0"
            >
              <template v-for="(client,i) in props.items">
                <v-list-item :key="'clientSelected-'+i">
                  <v-list-item-icon class="mr-2 d-flex align-center">
                    <v-icon :color="client.icon.markerColor">mdi-account-tie</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-medium">{{client.name}} ({{client.co_cli}})</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{client.ven}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="'cliAreadiv-'+i" />
              </template>
            </v-list>
          </template>
          <template v-slot:no-data>
          <div class="text-center pa-4">
            <v-icon color="blue-grey" left>mdi-shield-alert-outline</v-icon>
            <span class="blue-grey--text font-italic">No se encontraron Clientes dentro del Área seleccionada</span>
          </div>
          </template>
        </v-data-iterator>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions class="d-flex justify-end grey lighten-5">
        <v-btn
          tile
          color="blue-grey"
          class="px-8"
          outlined
          @click="show = false"
        >
          <v-icon dark left>mdi-location-exit</v-icon>
          Descartar
        </v-btn>
        <v-btn
          tile
          color="primary"
          class="px-8"
          :disabled="clients.length === 0"
          @click="exportClients"
          :loading="download"
        >
          <v-icon dark left>mdi-download</v-icon>
          Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ModalClienteExport',
  props:{
    value: Boolean,
    clients: {
      type: Array,
      default: () => ([])
    }
  },
  data(){
    return {
      show: this.value,
      download: false
    }
  },
  watch: {
    value(val, oldVal){
      if(val === oldVal) return;

      this.show = val;
    },
    show(val, oldVal){
      if(val === oldVal) return;

      this.$emit('input', val);
    }
  },
  filters:{
    hora(fecha){
      return moment(fecha).format('hh:mm:ss a');
    }
  },
  methods:{
    async exportClients() {
      this.download = true;
      const dataClientes= {
        clientes: this.clients.map(item => {
          return {
            co_cli: item.co_cli,
            cli_des: item.name,
            co_ven: item.co_ven,
            ven_des: item.ven,
          }
        })
      };
      try {
        const { data } = await this.$api.post('clientes/poligono', dataClientes, {responseType: 'blob'});

        var UrlFile = window.URL.createObjectURL(new Blob([data]));
        var FileLink = document.createElement('a');

        FileLink.href = UrlFile;
        FileLink.setAttribute('download',`Listado_de_Clientes_${moment().format('DDMMYYms')}.xlsx`);
        document.body.appendChild(FileLink);
        FileLink.click();
        FileLink.remove();
         this.$root.$showAlert(
          'Solicitud Exitosa. Por favor espere que se inicie la Descarga.',
          'success',
        )
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener El listado de Clientes.',
          'error'
        )
      }

      this.download = false;
    }
  }
}
</script>
<style lang="sass" scoped>

</style>
